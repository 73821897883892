.title[data-v-14d12dad] {
  margin-bottom: 0;
}
.user-tabs[data-v-14d12dad] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tab-container[data-v-14d12dad] {
  height: 100%;
  overflow: auto;
}
