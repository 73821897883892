.role_type_container[data-v-49355d88] {
  margin-bottom: 8px;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 8px;
}
.role_container[data-v-49355d88] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.role_container div[data-v-49355d88] {
  margin: 4px 0;
  width: 33.33%;
}
