.permission_type_container[data-v-7c22770d] {
  margin-bottom: 8px;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 8px;
}
.permission_container[data-v-7c22770d] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.permission_container div[data-v-7c22770d] {
  margin: 4px 0;
  width: 33.33%;
}
.permission_container span[data-v-7c22770d] {
  margin-right: 8px;
}
