.filter-form[data-v-a0327c9a] .el-form-item--small {
  margin-bottom: 8px;
}
.filter-form[data-v-a0327c9a] label {
  margin-bottom: 0;
}
.sider-container[data-v-a0327c9a] {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 8px;
}
.sider-container .btn-container[data-v-a0327c9a] {
  margin-bottom: 8px;
}
.sider-container .user-container[data-v-a0327c9a] {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.sider-container .activity[data-v-a0327c9a] {
  color: #286840;
}
